import React from "react";
import BottomTabs from "../Components/BottomTabs/BottomTabs";
import Header from "../Components/Header/Header";
import FeedbackComponent from "../Components/Feedback/FeedbackComponent";

import "../Stylesheets/Home.css";

function FeadBackPage() {
  return (
    <div className="Home-page-container">
      <Header />
      <div className="Middle-content-Friendspage"></div>
      <section>
        <FeedbackComponent />
      </section>
      <BottomTabs></BottomTabs>
    </div>
  );
}

export default FeadBackPage;
