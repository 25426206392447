import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./CoverSection.css"; // Import your CSS for styling
import campic from "../../assets/icons/Camera.png";
import { useUser } from "../../Contexts/UserContext";

const CoverSection = () => {
  const [coverImageUrl, setCoverImageUrl] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { user } = useUser();
  const [uploadProgress, setUploadProgress] = useState(0);
  const fileInputRef = useRef(null);

  const userId = user.userId; // Replace with actual user ID

  useEffect(() => {
    const fetchCoverImage = async () => {
      try {
        const response = await axios.get(
          `https://coblog-backend.vercel.app/coverPicture`,
          {
            params: { userId },
          }
        );

        if (response.data.profilePictureUrl) {
          setCoverImageUrl(response.data.profilePictureUrl);
        }
      } catch (error) {
        console.error("Error fetching cover image:", error);
      }
    };

    fetchCoverImage();
  }, [userId]);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert("Please select a file first.");
      return;
    }

    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("coverPicture", selectedFile);

    setShowModal(false);

    try {
      await axios.patch(
        "https://coblog-backend.vercel.app/coverPicture",
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          },
        }
      );

      const response = await axios.get(
        `https://coblog-backend.vercel.app/coverPicture`,
        {
          params: { userId },
        }
      );

      if (response.data.profilePictureUrl) {
        setCoverImageUrl(response.data.profilePictureUrl);
        setSelectedFile(null); // Clear selected file
      }
    } catch (error) {
      console.error("Error uploading cover image:", error);
    } finally {
      setUploadProgress(0);
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="cover-section">
      {coverImageUrl ? (
        <img src={coverImageUrl} alt="Cover" className="cover-image" />
      ) : (
        <button
          onClick={handleOpenModal}
          className="upload-button-camera-nocover"
        >
          <img
            src={campic}
            className="upload-button-camera-img-nocover"
            alt="No cover"
          />
          Upload Cover Picture
        </button>
      )}

      <button onClick={handleOpenModal} className="upload-button-camera">
        <img src={campic} className="upload-button-camera-img"></img>
        {coverImageUrl ? "" : "Upload Cover Picture"}
      </button>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content-cover">
            <h2>Upload Cover Picture</h2>
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className="file-input"
              ref={fileInputRef}
            />
            {selectedFile && (
              <div className="image-preview">
                <img
                  src={URL.createObjectURL(selectedFile)}
                  alt="Selected"
                  className="preview-image"
                />
                <p>{selectedFile.name}</p>
              </div>
            )}
            <button onClick={handleUpload} className="upload-button">
              Upload
            </button>
            <button onClick={handleCloseModal} className="close-button">
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CoverSection;
