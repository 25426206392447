import React from "react";
import "./MarkdownGuide.css";

const MarkdownGuide = () => {
  return (
    <section className="markdown-guide">
      <h1>How to Write the Perfect Blog in Markdown</h1>
      <p>
        Markdown is a lightweight markup language that you can use to add
        formatting elements to plaintext text documents. Here’s how to use it
        effectively for writing blogs.
      </p>

      <div className="guide-section">
        <h2>1. Start with a Strong Title</h2>
        <p>
          Use a level 1 heading for your main title. This helps in defining the
          main topic of your blog.
        </p>
        <pre>
          <code># Your Blog Title</code>
        </pre>
      </div>

      <div className="guide-section">
        <h2>2. Use Subheadings</h2>
        <p>
          Break your content into sections with subheadings. Use level 2 or
          level 3 headings for subtopics.
        </p>
        <pre>
          <code>## Subheading</code>
        </pre>
        <pre>
          <code>### Sub-subheading</code>
        </pre>
      </div>

      <div className="guide-section">
        <h2>3. Highlight Important Information</h2>
        <p>
          Use bold and italic text to emphasize key points and important
          information.
        </p>
        <pre>
          <code>**Bold Text**</code>
        </pre>
        <pre>
          <code>*Italic Text*</code>
        </pre>
      </div>

      <div className="guide-section">
        <h2>4. Include Lists</h2>
        <p>Organize information using ordered or unordered lists.</p>
        <pre>
          <code>- Item 1</code>
        </pre>
        <pre>
          <code>- Item 2</code>
        </pre>
        <pre>
          <code>1. First Item</code>
        </pre>
        <pre>
          <code>2. Second Item</code>
        </pre>
      </div>

      <div className="guide-section">
        <h2>5. Add Blockquotes</h2>
        <p>Use blockquotes for important quotes or excerpts.</p>
        <pre>
          <code>&gt; This is a blockquote.</code>
        </pre>
      </div>

      <div className="guide-section">
        <h2>6. Embed Images</h2>
        <p>Include images to make your blog visually appealing.</p>
        <pre>
          <code>![Alt Text](https://example.com/image.jpg)</code>
        </pre>
      </div>

      <div className="guide-section">
        <h2>7. Link to Resources</h2>
        <p>Provide links to external resources for more information.</p>
        <pre>
          <code>[Link Text](https://example.com)</code>
        </pre>
      </div>

      <div className="guide-section">
        <h2>8. Preview Your Work</h2>
        <p>Always preview your Markdown to ensure it renders as expected.</p>
      </div>

      <p>
        By following these guidelines, you can write well-structured and
        engaging blog posts using Markdown.
      </p>
    </section>
  );
};

export default MarkdownGuide;
