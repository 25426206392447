// src/components/Header.js
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Header.css";
import logo from "./Logo.png";
import createIcon from "./Create.png";
import { useUser } from "../../Contexts/UserContext";

const Header = () => {
  const navigate = useNavigate();
  const { user } = useUser();

  const handleCreateClick = () => {
    navigate("/createnewblog");
  };

  const handleLoginClick = () => {
    navigate("/login");
  };

  const handleSignupClick = () => {
    navigate("/signup");
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <header className="header">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" onClick={handleLogoClick} />
      </div>
      <div className="menu-icon-container">
        {user.userId ? (
          <div className="create-btn-box">
            <img
              src={createIcon}
              alt="Create"
              className="Create-Blog-btn"
              onClick={handleCreateClick}
            />
          </div>
        ) : (
          <div className="auth-buttons">
            <button className="login-btn" onClick={handleLoginClick}>
              Login
            </button>
            <button className="signup-btn" onClick={handleSignupClick}>
              Signup
            </button>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
