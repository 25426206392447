import React from "react";
import BottomTabs from "../Components/BottomTabs/BottomTabs";
import Profilebody from "../Components/AccountDetails/Profilebody";
import BackButton from "../assets/icons/back.png";
import ThreeDots from "../Components/PostComponents/dots.png";
import UserPostedBlogs from "../Components/AccountDetails/UserPosted";
import CreatePostAccount from "../Components/AccountDetails/CreatePostAccount";
import LoginPrompt from "../Components/Errors/LoginPrompt";
import { useUser } from "../Contexts/UserContext";
import Following from "../Components/Smallcomponent/Following";

import { useNavigate } from "react-router-dom";
import CoverSection from "../Components/AccountDetails/CoverSection";

function Account() {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const { user } = useUser();

  //
  //
  //

  if (!user || user.userId.length === 0 || user.name === "") {
    return <LoginPrompt />;
  }
  //

  return (
    <div className="account-page-bg">
      {" "}
      <div className="header-comments">
        <img
          src={BackButton}
          alt="Back"
          className="backButton"
          onClick={goBack}
        />
        <h2 className="title">Account Page</h2>
        <img src={ThreeDots} alt="Options" className="threeDots" />
      </div>
      <div className="Account-page-container">
        <Profilebody></Profilebody>
        <Following />
        <CreatePostAccount></CreatePostAccount>
        <section
          className="User-posted-bx-account"
          style={{ paddingBottom: "30px" }}
        >
          <UserPostedBlogs />
        </section>
        {/* Blog list commented for now we will display only the users posted blogs in account sections */}
        <BottomTabs></BottomTabs>
      </div>
    </div>
  );
}

export default Account;
