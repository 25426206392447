// src/components/BlogDetails.jsx
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import BlogTemplate from "./BlogTemplate";
import createimg from "../Header/Create.png";
import AjaxLoader from "../Loaders/AjaxLoader";
import Head from "../Header/Header";

// Helper function to convert binary data to base64 string
const arrayBufferToBase64 = (buffer) => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

const BlogDetails = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const handlestickycreate = () => {
    navigate(`/create`);
  };

  const fetchBlog = async () => {
    try {
      const response = await axios.get(
        `https://coblog-backend.vercel.app/blogs/${id}`
      );
      setBlog(response.data);
    } catch (error) {
      console.error("Failed to fetch blog:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBlog();
  }, [id]);

  if (isLoading) {
    return <AjaxLoader />;
  }

  if (!blog) {
    return <div className="text-center">Blog not found</div>;
  }

  // Convert binary data to base64 string
  const base64String = blog.blogpicture
    ? `data:image/jpeg;base64,${arrayBufferToBase64(blog.blogpicture.data)}`
    : "https://images.pexels.com/photos/262508/pexels-photo-262508.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500";

  return (
    <div className="blog-page">
      <Head></Head>
      <section className="Blogdetails-mid-section">
        <BlogTemplate
          authorName={blog.author_name}
          title={blog.title}
          text={blog.content}
          createdOn={blog.created_at}
          imageUrl={base64String}
          blogLink={`https://blogbook.digihut.store/blog/${blog.blog_id}`}
        />
        <section className="ation-btn-bycategory-page">
          <button
            className="btn-action-to-post-sticky"
            onClick={handlestickycreate}
          >
            Post a blog{" "}
            <img
              src={createimg}
              alt="Like"
              className="action-btn-box-bycategory-post-create"
            />
          </button>
        </section>
      </section>
    </div>
  );
};

export default BlogDetails;
