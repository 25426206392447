import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import BackButton from "../assets/icons/back.png";
import UserHead from "../Components/PostComponents/UserHead";
import delicon from "../assets/icons/Del.png";
import ThreeDots from "../Components/PostComponents/dots.png";
import PostComponent from "../assets/icons/Send.png";
import "../Stylesheets/Comments.css";
import defaultProfilePic from "../Components/PostComponents/profile.png";
import { useUser } from "../Contexts/UserContext";
import AjaxLoaderGif from "../Components/Blogs/AjaxLoader.gif";

function Comments() {
  const [isExpanded, setIsExpanded] = useState(false);
  const [comments, setComments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isdeleting, setisdeleting] = useState(false);
  const [sending, setSending] = useState(false);
  const { user } = useUser();
  const [commentText, setCommentText] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { title, text, authorName, createdOn, imageUrl, BlogId } =
    location.state;

  const goBack = () => {
    navigate(-1);
  };

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  const renderText = () => {
    if (isExpanded || text.length <= 100) {
      return text;
    }
    return text.substring(0, 100) + "...";
  };

  const fetchComments = async () => {
    try {
      const response = await axios.get(
        `https://coblog-backend.vercel.app/comments/${BlogId}`
      );
      const commentsData = response.data;

      // Fetch profile pictures for each commenter
      const commentsWithPhotos = await Promise.all(
        commentsData.map(async (comment) => {
          try {
            const photoResponse = await axios.get(
              `https://coblog-backend.vercel.app/users/profilePicture`,
              {
                headers: {
                  "Content-Type": "application/json",
                },
                params: {
                  userId: comment.user_id,
                },
              }
            );
            return {
              ...comment,
              photo: photoResponse.data.profilePictureUrl || defaultProfilePic,
            };
          } catch (error) {
            return {
              ...comment,
              photo: defaultProfilePic, // Fallback to default profile picture
            };
          }
        })
      );

      setComments(commentsWithPhotos);
    } catch (error) {
      console.error("Failed to fetch comments:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const postComment = async () => {
    if (!commentText) return;

    setSending(true);

    try {
      await axios.patch(
        `https://coblog-backend.vercel.app/comments/${BlogId}`,
        {
          userId: user.userId,
          commentText,
        }
      );
      setCommentText("");
      fetchComments();
    } catch (error) {
      console.error("Failed to post comment:", error);
    } finally {
      setSending(false);
    }
  };

  const deleteComment = async (commentId) => {
    try {
      setisdeleting(true);
      await axios.delete(
        `https://coblog-backend.vercel.app/comments/${commentId}`,
        {
          data: { userId: user.userId },
        }
      );
      setisdeleting(false);
      fetchComments();
    } catch (error) {
      console.error("Failed to delete comment:", error);
    }
  };

  useEffect(() => {
    fetchComments();
  }, [BlogId]);

  return (
    <>
      <div className="header-comments">
        <img
          src={BackButton}
          alt="Back"
          className="backButton"
          onClick={goBack}
        />
        <h2 className="title">Comments</h2>
        <img src={ThreeDots} alt="Options" className="threeDots" />
      </div>

      <section className="selectedblog">
        <div className="blog-card">
          <UserHead name={authorName} createdOn={createdOn} />
          <div className="blog-content-box">
            <h2 className="blog-title">{title}</h2>
            <p className="blog-content">{renderText()}</p>
          </div>
          {renderText().length > 100 && (
            <button onClick={toggleText} className="read-more-btn">
              {isExpanded ? "Read Less" : "Read More"}
            </button>
          )}
          <img src={imageUrl} alt="Blog" className="blog-image" />
        </div>
      </section>

      <section className="selectedblog-comments">
        {isLoading && (
          <div className="loading-container">
            <img src={AjaxLoaderGif} alt="Loading..." className="loading-gif" />
          </div>
        )}
        <div className="comments-section" style={{ marginBottom: "50px" }}>
          <div className="comments-list">
            {comments.map((comment) => (
              <div key={comment.comment_id} className="comment">
                <div className="comment-content">
                  <div className="coment-name-commentor">
                    <img
                      src={comment.photo}
                      alt="Profile"
                      className="comment-profile-pic"
                    />
                    <section className="comment-username-box">
                      <p className="comment-name">{comment.username}</p>
                    </section>
                    <section className="comment-del-box">
                      <button
                        onClick={() => deleteComment(comment.comment_id)}
                        className="btn-del-comment"
                        disabled={isdeleting}
                      >
                        <img
                          src={delicon}
                          alt="delete-comments"
                          className="del-icon-comment"
                        ></img>
                      </button>

                      {isdeleting ? (
                        <img
                          src={AjaxLoaderGif}
                          alt="Loading..."
                          className="loading-gif"
                        />
                      ) : null}
                    </section>
                  </div>
                  <p className="comment-message">{comment.comment_text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <div className="commentInput">
        <input
          type="text"
          placeholder="Add your comment..."
          className="inputField-comment-sestion"
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
        />
        <div className="post-comment-btn" onClick={postComment}>
          {sending ? (
            <img src={AjaxLoaderGif} alt="Loading..." className="loading-gif" />
          ) : (
            <img src={PostComponent} alt="Post" className="post-comment-icon" />
          )}
        </div>
      </div>
    </>
  );
}

export default Comments;
