import React, { useState } from "react";
import "./Threedots.css";
import axios from "axios";

// Confirmation Modal Component
const ConfirmationModal = ({ show, onClose, onConfirm }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <div className="modal-content">
          <h4>Confirm Delete</h4>
          <p>Are you sure you want to delete this blog?</p>
          <div className="modal-actions">
            <button onClick={onConfirm} className="confirm-button-delete">
              Yes
            </button>
            <button onClick={onClose} className="cancel-button-delete">
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Dotclick = ({
  show,
  onClose,

  blogId,
  blogLink,
}) => {
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  if (!show) {
    return null;
  }

  const handleDel = async () => {
    setConfirmModalVisible(false); // Hide the confirmation modal

    try {
      const response = await axios.delete(
        "https://coblog-backend.vercel.app/blogs/delete",
        {
          data: { blogId: blogId },
        }
      );

      onClose(); // Close the main modal after deletion
    } catch (error) {
      console.error("Error deleting the blog:", error);
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(blogLink).then(
      () => {
        onClose();
      },
      (err) => {
        console.error("Failed to copy: ", err);
      }
    );
  };

  return (
    <>
      <div className="modal-overlay-threedots" onClick={onClose}>
        <div className="modal-threedots" onClick={(e) => e.stopPropagation()}>
          <div className="line"></div>

          <div className="modal-buttons-threedots">
            <button onClick={handleCopyLink} className="modal-button">
              Copy Link
            </button>

            <button onClick={onClose} className="modal-button close-button">
              Close
            </button>
          </div>
        </div>
      </div>

      <ConfirmationModal
        show={confirmModalVisible}
        onClose={() => setConfirmModalVisible(false)}
        onConfirm={handleDel}
      />
    </>
  );
};

export default Dotclick;
