import React from "react";
import BottomTabs from "../Components/BottomTabs/BottomTabs";
import SearchBody from "../Components/Explorecomponents/SearchBody";

import "../Stylesheets/Home.css";

function Explore() {
  return (
    <div className="Home-page-container">
      <div className="Middle-content-Explore">
        <SearchBody />
      </div>
      <BottomTabs></BottomTabs>
    </div>
  );
}

export default Explore;
