import React, { useEffect, useState } from "react";
import axios from "axios";
import { useUser } from "../../Contexts/UserContext";

const Following = () => {
  const [followerCount, setFollowerCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const { user } = useUser();

  useEffect(() => {
    const fetchFollowerCount = async () => {
      try {
        const response = await axios.get(
          `https://coblog-backend.vercel.app/users/followedUsers/${user.userId}`
        );
        setFollowerCount(response.data.length);
      } catch (error) {
        console.error("Failed to fetch follower count:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFollowerCount();
  }, [user.userId]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        flexDirection: "row",
        paddingTop: "5px",
        marginTop: "5px",
        marginBottom: "5px",
        height: 20,
        backgroundColor: "#fff",
        borderRadius: "2px",
      }}
    >
      <h2 style={{ marginBottom: "10px", fontSize: "10px" }}>Following</h2>
      {loading ? (
        <p style={{ fontSize: "10px", color: "#888" }}>Loading...</p>
      ) : (
        <p style={{ fontSize: "10px", fontWeight: "bold" }}>{followerCount}</p>
      )}
    </div>
  );
};

export default Following;
