import React from "react";
import Loader from "../Blogs/AjaxLoader.gif";
import "./AjaxLoader.css";

const AjaxLoader = () => {
  return (
    <>
      <div className="Ajaxloader-container">
        <img src={Loader} alt="Ajax-loader" className="Ajax-giff" />
      </div>
    </>
  );
};

export default AjaxLoader;
