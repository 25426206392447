import React from "react";
import { useNavigate } from "react-router-dom";
import "./LoginPrompt.css"; // Import your CSS file
import loginillastration from "../../assets/ErrorsPngs/Login.png";

const LoginPrompt = () => {
  //
  //
  const Goto = useNavigate();
  //
  //
  const handleLoginClick = () => {
    Goto("/login");
  };
  //
  //

  return (
    <div className="login-prompt-container">
      <div className="login-prompt-content">
        <img
          src={loginillastration}
          alt="Login Illustration"
          className="login-prompt-image"
        />
        <p className="login-prompt-message">
          Login to see your account details
        </p>
        <button className="login-prompt-button" onClick={handleLoginClick}>
          Login
        </button>
      </div>
    </div>
  );
};

export default LoginPrompt;
