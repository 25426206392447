import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import BlogTemplate from "./BlogTemplate";
import AjaxLoaderGif from "./AjaxLoader.gif";

import "./BlogList.css";

// Helper function to convert binary data to base64 string
const arrayBufferToBase64 = (buffer) => {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const loaderRef = useRef(null);

  const fetchBlogs = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://coblog-backend.vercel.app/blogs/?limit=2&page=${page}`
      );
      setBlogs((prevBlogs) => [...prevBlogs, ...response.data.blogs]);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Failed to fetch blogs:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogs(currentPage);
  }, [currentPage]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !loading && currentPage < totalPages) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      },
      {
        rootMargin: "100px",
      }
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [loading, currentPage, totalPages]);

  return (
    <div className="container-Bloglist">
      <div className="blog-grid">
        {blogs.map((blog) => {
          const base64String = blog.blogpicture
            ? `data:image/jpeg;base64,${arrayBufferToBase64(
                blog.blogpicture.data
              )}`
            : "default_picture_url_here";
          return (
            <div key={blog.blog_id}>
              <BlogTemplate
                authorName={blog.author_name}
                title={blog.title}
                text={blog.content}
                createdOn={blog.created_at}
                imageUrl={base64String}
                blogLink={`https://blogbook.digihut.store/blog/${blog.blog_id}`}
                userId={blog.user_id}
                BlogId={blog.blog_id}
                likes={blog.likes_count}
                TotalComment={blog.comments_count}
              />
            </div>
          );
        })}
        {loading && (
          <div className="loading-container">
            <p>Loading New Blogs ...</p>
            <img src={AjaxLoaderGif} alt="Loading..." className="loading-gif" />
          </div>
        )}
      </div>
      <div ref={loaderRef} className="loading-trigger"></div>
    </div>
  );
};

export default BlogList;
