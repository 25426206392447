import React, { useState } from "react";
import axios from "axios";
import "./FeedbackComponent.css"; // Import the CSS file for styling
import { useUser } from "../../Contexts/UserContext";

const FeedbackComponent = () => {
  const [starCount, setStarCount] = useState(1);
  const [feedback, setFeedback] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const { user } = useUser();

  const handleStarClick = (count) => {
    setStarCount(count);
  };

  const handleInputChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        "https://coblog-backend.vercel.app/feedback",
        {
          userId: user.userId,
          text: feedback,
          stars: starCount,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Feedback submitted:", response.data);
      setSubmitted(true);
      // Optionally, you can reset the form here if needed
      setFeedback("");
      setStarCount(1);
    } catch (error) {
      console.error("Error submitting feedback:", error);
      // Optionally, you can show an error message here
    }
  };

  return (
    <div className="feedback-container">
      {submitted ? (
        <div className="thank-you-message">
          <h2 className="Thanks-hearts">❤❤</h2>
          <h2>Thank you for your feedback! </h2>
        </div>
      ) : (
        <>
          <div className="Feadback-Psyco">
            <h2 className="Feadback-Psyco-h2">👋 {user.name}</h2>
            <p className="Feadback-Psyco-p">How was your experience so far?</p>
          </div>
          <div className="star-rating">
            {[1, 2, 3, 4, 5].map((star) => (
              <span
                key={star}
                className={`star ${starCount >= star ? "selected" : ""}`}
                onClick={() => handleStarClick(star)}
              >
                ★
              </span>
            ))}
          </div>
          <textarea
            className="feedback-input"
            placeholder="Please share your feedback"
            value={feedback}
            onChange={handleInputChange}
          />
          <button className="submit-button" onClick={handleSubmit}>
            Submit
          </button>
        </>
      )}
    </div>
  );
};

export default FeedbackComponent;
