import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import "../../Stylesheets/CreateNewBlog.css";
import loadgif from "./AjaxLoader.gif";

import { useUser } from "../../Contexts/UserContext";

function CreateNewBlog() {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [category, setCategory] = useState(""); // New state for category
  const [picture, setPicture] = useState(null); // New state for picture
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { user } = useUser();

  const navigate = useNavigate();

  useEffect(() => {
    if (user.userId) {
      navigate("/create");
    } else {
      navigate("/login");
    }
  }, [navigate, user.userId]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError("");

    if (content.length === 0) {
      setError("Content cannot be empty");
      return;
    }
    if (!category) {
      setError("Category must be selected");
      return;
    }

    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    formData.append("author_name", user.name);
    formData.append("userId", user.userId);
    formData.append("category", category); // Add category to formData
    formData.append("blogPicture", picture); // Add picture to formData

    try {
      setLoading(true);
      const r = await axios.post(
        "https://coblog-backend.vercel.app/postblog",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setLoading(false);
      navigate("/home");
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };

  return (
    <section className="creatNew-container">
      <div className="container-create-new-blog">
        <form onSubmit={handleSubmit} className="form">
          <div className="input-box">
            <label htmlFor="category" className="create-blog-Labels">
              Select Post Category *Required
            </label>
            <select
              id="category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="Blog-category-select"
              required
            >
              <option value="" disabled>
                Example: Technology
              </option>
              <option value="Technology">Technology</option>
              <option value="Artificial Intelligence">
                Artificial Intelligence
              </option>
              <option value="Travelling">Travelling</option>
              <option value="Web Development">Web Development</option>
              <option value="Health">Health</option>
              <option value="Travel">Travel</option>
              <option value="Lifestyle">Lifestyle</option>
              <option value="Food">Food</option>
              <option value="Geopolitics">Geopolitics</option>
              <option value="Finance">Finance</option>
              <option value="Economy">Economy</option>
              <option value="Education">Education</option>
              <option value="Entertainment">Entertainment</option>
              <option value="DIY and Crafts">DIY and Crafts</option>
              <option value="Parenting">Parenting</option>
              <option value="Love and Relationships">
                Love and Relationships
              </option>
              <option value="Horror Stories">Horror Stories</option>
              <option value="Sports">Sports</option>
              <option value="Fashion">Fashion</option>
              <option value="Science">Science</option>
              <option value="Environment">Environment</option>
              <option value="Art and Culture">Art and Culture</option>
              <option value="History">History</option>
              <option value="Poetry">Poetry</option>

              <option value="Automobiles">Automobiles</option>
              <option value="Photography">Photography</option>
            </select>
          </div>

          <div className="input-box">
            <label htmlFor="blogTitle" className="create-blog-Labels">
              Blog Title *Required
            </label>
            <input
              type="text"
              id="blogTitle"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Write the title of your blog"
              className="Blog-title-input"
            />
          </div>
          <div className="input-box">
            <label htmlFor="message" className="create-blog-Labels">
              Blog Content *Required
            </label>
            <textarea
              id="message"
              rows="6"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              className="Blog-Body-input"
              placeholder="Share your thoughts with the world"
              required
            ></textarea>
          </div>

          <div className="input-box">
            <label htmlFor="blogPicture" className="create-blog-Labels">
              Upload Picture *Optional
            </label>
            <input
              type="file"
              id="blogPicture"
              accept="image/*"
              onChange={(e) => setPicture(e.target.files[0])}
              className="Blog-picture-input"
              placeholder="Select a picture for your post"
            />
          </div>
          {error && <div className="Error">{error}</div>}
          <div className="Post-section-btn">
            <button
              type="submit"
              className="Post-btn-blog"
              onClick={handleSubmit}
            >
              {loading ? (
                <img src={loadgif} alt="Loading..." className="loading-gif" />
              ) : (
                "Post Blog"
              )}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default CreateNewBlog;
