import React from "react";

const NoSearch = () => {
  return (
    <>
      <section className="Error-container" style={{ marginTop: "5%" }}>
        <h3 style={{ textAlign: "center" }}>
          You Have't Searched anything yet
        </h3>
        <h5 style={{ textAlign: "center" }}>
          Search your favorite blog, writer, or enter a keyword in the search
          box above.
        </h5>
      </section>
    </>
  );
};

export default NoSearch;
