import { useState, useEffect } from "react";
import "../PostComponents/CreatePost.css";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../Contexts/UserContext";
import axios from "axios";

const CreatePostAccount = () => {
  const Navigate = useNavigate();
  const { user } = useUser();
  const [profilePictureUrl, setProfilePictureUrl] = useState("");
  const [username, setUsername] = useState("");

  //
  //
  useEffect(() => {
    const userData = localStorage.getItem("user");
    if (userData) {
      const user = JSON.parse(userData);
      setUsername(user.name);
    }
  }, []);
  //
  //
  useEffect(() => {
    const fetchProfilePicture = async () => {
      if (user && user.userId) {
        try {
          const response = await axios.get(
            `https://coblog-backend.vercel.app/users/profilePicture`,
            {
              headers: {
                "Content-Type": "application/json",
              },
              params: {
                userId: user.userId, // Send userId as query parameter
              },
            }
          );

          if (response.data && response.data.profilePictureUrl) {
            setProfilePictureUrl(response.data.profilePictureUrl);
          } else {
            setProfilePictureUrl(""); // Handle no profile picture
          }
        } catch (error) {
          console.error("Failed to fetch profile picture:", error);
          setProfilePictureUrl(""); // Handle errors
        } finally {
        }
      } else {
      }
    };

    fetchProfilePicture();
  }, []);

  //
  //
  //

  const Handleprofile = () => {
    Navigate("/Account");
  };

  const HandleCreate = () => {
    Navigate("/Create");
  };

  return (
    <section className="cont-Create-post">
      <div className="create-post-container">
        <div className="profile-photo-container" onClick={Handleprofile}></div>
        <div className="input-container">
          <input
            onClick={HandleCreate}
            type="text"
            placeholder={`Dear "${username}" Write your amazing post today`}
            className="input-field"
          />
        </div>
      </div>
    </section>
  );
};

export default CreatePostAccount;
