import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./SearchBody.css";
import soon from "./SearchPNg.png";
import NoSearch from "../Errors/Explore/TypeTo";

const SearchBody = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setResults([]);
      return;
    }

    const fetchResults = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(
          `https://coblog-backend.vercel.app/search?query=${searchQuery}&page=1&limit=5`
        );
        const data = await response.json();

        setResults(data.blogs || []);
      } catch (error) {
        setError("Failed to fetch search results");
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, [searchQuery]);

  const truncateContent = (content, maxLines = 2) => {
    const lines = content.split("\r\n").filter((line) => line.trim() !== "");
    return (
      lines.slice(0, maxLines).join(" ") +
      (lines.length > maxLines ? "..." : "")
    );
  };

  const handleBlogClick = (id) => {
    navigate(`/blog/${id}`);
  };

  return (
    <div className="Middle-content-Explore">
      <div className="search-container">
        <input
          type="text"
          className="search-input"
          placeholder="Search blogs, authors, more..."
          value={searchQuery}
          onChange={handleSearchChange}
        />

        <section className="Explore-results-section">
          <div className="result-box">
            {loading ? (
              <p>Loading...</p>
            ) : error ? (
              <p>{error}</p>
            ) : results.length > 0 ? (
              results.map((blog) => (
                <div
                  key={blog.blog_id}
                  className="blog-item"
                  onClick={() => handleBlogClick(blog.blog_id)}
                >
                  <h3 className="result-title">{blog.title}</h3>
                  <p>
                    <strong>Author:</strong> {blog.author_name}
                  </p>
                  <p className="result-content">
                    {truncateContent(blog.content)}
                  </p>
                </div>
              ))
            ) : (
              <NoSearch />
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default SearchBody;
