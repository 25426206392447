// src/components/CategoryBody.jsx
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./CategoryBody.css";
import axios from "axios";
import CategoriesBodySkeleton from "../Skeletons/CategoriesBodySkeleton";

const CategoryBody = () => {
  const [categories, setCategories] = useState([]); // State to hold categories
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch categories from API
    axios
      .get("https://coblog-backend.vercel.app/categories")
      .then((response) => {
        setCategories(response.data); // Update state with API response
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []); // Empty dependency array to run effect only once

  if (loading) return <CategoriesBodySkeleton />;

  return (
    <div className="category-body">
      <h2 className="body-title">Categories</h2>
      <div className="category-grid">
        {categories.map((category, index) => (
          <div
            key={index}
            className="category-item"
            style={{ cursor: "pointer" }}
          >
            <img
              src="https://cdn-icons-png.flaticon.com/512/46/46955.png"
              alt={category.category}
              onClick={() =>
                navigate(`/posts/${encodeURIComponent(category.category)}`)
              }
            />
            <h3>{category.category}</h3>
            <button className="cate-follow-button">Follow</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryBody;
