import React, { useState, useEffect } from "react";
import "./NotificationToast.css"; // Import the CSS for styling

const NotificationToast = ({ message, type, duration, onClose }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      if (onClose) onClose();
    }, duration || 3000); // Default duration is 3000ms

    return () => clearTimeout(timer); // Clean up timer on unmount
  }, [duration, onClose]);

  if (!visible) return null;

  return (
    <div className={`notification-toast ${type}`}>
      <span>{message}</span>
    </div>
  );
};

export default NotificationToast;
