import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import BlogCard from "../Blogs/BlogCard";
// import "./Postbycategory.css";

const Postbycategory = () => {
  const { categoryName } = useParams();
  const decodedCategoryName = decodeURIComponent(categoryName);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchPosts = async () => {
    try {
      const response = await axios.get(
        `https://coblog-backend.vercel.app/categories/${decodedCategoryName}`
      );
      setPosts(response.data); // Update state with API response
      setLoading(false);
    } catch (error) {
      console.error("Error fetching posts:", error);
      setError("Error fetching posts");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, [decodedCategoryName]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="post-category-container">
      <h2>{decodedCategoryName}</h2>
      <div className="posts-grid">
        {posts.map((post) => (
          <BlogCard
            key={post.blog_id}
            id={post.blog_id}
            title={post.title}
            content={post.content}
            authorName={post.author_name}
            createdAt={post.created_at}
          />
        ))}
      </div>
    </div>
  );
};

export default Postbycategory;
