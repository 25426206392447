import React from "react";
import BottomTabs from "../Components/BottomTabs/BottomTabs";
import Header from "../Components/Header/Header";
import Followers from "../Components/Friends/Followers";
import Following from "../Components/Smallcomponent/Following";

import "../Stylesheets/Home.css";

function Friendspage() {
  return (
    <div className="Home-page-container">
      <Header />
      <div className="Middle-content-Friendspage"></div>
      {/* <h2 className="Your-friend-h2 ">Your friends </h2> */}
      <Following />
      <Followers />
      <BottomTabs></BottomTabs>
    </div>
  );
}

export default Friendspage;
