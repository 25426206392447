import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Followers.css"; // Make sure to create appropriate styles
import { useUser } from "../../Contexts/UserContext";

const Followers = () => {
  const [followers, setFollowers] = useState([]);
  const { user } = useUser();
  const FollowerId = user.userId;

  useEffect(() => {
    const fetchFollowers = async () => {
      try {
        const response = await axios.get(
          `https://coblog-backend.vercel.app/users/followedUsers/${FollowerId}`
        );
        setFollowers(response.data);
      } catch (error) {
        console.error("Error fetching followed users:", error);
      }
    };

    fetchFollowers();
  }, [FollowerId]);

  const handleUnfollow = async (followedId) => {
    try {
      await axios.post(
        "https://coblog-backend.vercel.app/following/unfollowUser",
        {
          follower_id: FollowerId,
          followed_id: followedId,
        },
        { headers: { "Content-Type": "application/json" } }
      );
      setFollowers((prevFollowers) =>
        prevFollowers.filter((follower) => follower.user_id !== followedId)
      );
    } catch (error) {
      console.error("Error unfollowing user:", error);
    }
  };

  const handleSendMessage = (followedId) => {
    // Handle message action here, e.g., navigate to a messaging component
    console.log(`Message user with ID: ${followedId}`);
  };

  return (
    <div className="followers-list">
      {followers.map((follower) => (
        <FollowerCard
          key={follower.user_id}
          follower={follower}
          onUnfollow={() => handleUnfollow(follower.user_id)}
          onSendMessage={() => handleSendMessage(follower.user_id)}
        />
      ))}
    </div>
  );
};

const FollowerCard = ({ follower, onUnfollow, onSendMessage }) => {
  const [profilePictureUrl, setProfilePictureUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchProfilePicture = async () => {
      try {
        const response = await axios.get(
          `https://coblog-backend.vercel.app/users/profilePicture`,
          {
            headers: {
              "Content-Type": "application/json",
            },
            params: {
              userId: follower.user_id,
            },
          }
        );

        if (response.data && response.data.profilePictureUrl) {
          setProfilePictureUrl(response.data.profilePictureUrl);
        } else {
          setProfilePictureUrl(""); // Handle no profile picture
        }
      } catch (error) {
        setProfilePictureUrl(""); // Handle errors
      } finally {
        setIsLoading(false);
      }
    };

    fetchProfilePicture();
  }, [follower.user_id]);

  const fallbackProfilePic =
    "https://cdn-icons-png.flaticon.com/128/3177/3177440.png"; // Update with the path to your fallback image

  return (
    <div className="follower-card">
      <div className="follower-left">
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <img
            src={profilePictureUrl || fallbackProfilePic}
            alt="Profile"
            className="follower-photo"
            onError={(e) => (e.target.src = fallbackProfilePic)}
          />
        )}
        <div className="follower-info">
          <span className="follower-name">{follower.username}</span>
        </div>
      </div>
      <div className="follower-right">
        <button onClick={onUnfollow} className="unfollow-btn">
          Unfollow
        </button>
        <button onClick={onSendMessage} className="message-btn">
          Message
        </button>
      </div>
    </div>
  );
};

export default Followers;
