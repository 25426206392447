// src/components/Create.jsx
import React from "react";
import CreateNewBlog from "../Components/Blogs/CreateNewBlog";
import backIcon from "../assets/icons/back.png";
import { useNavigate } from "react-router-dom";
// import axios from "axios";
import "../Stylesheets/Create.css";

const Create = () => {
  const Navigate = useNavigate();

  const handleBackClick = () => {
    Navigate("/home"); // This navigates back to the previous page
  };

  return (
    <div className="body-Create">
      <div className="create-header">
        <div className="back-button" onClick={handleBackClick}>
          <img src={backIcon} alt="Back" />
          <span>Back</span>
        </div>
      </div>

      <div className="content-container">
        <CreateNewBlog></CreateNewBlog>
      </div>
    </div>
  );
};

export default Create;
