import React from "react";
import { useNavigate } from "react-router-dom";
import likeIcon from "../Blogs/Icons/like.png";

import createimg from "../Header/Create.png";
import "./BlogCard.css";

const BlogCard = ({ id, title, content, authorName, createdAt }) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/blog/${id}`);
  };

  const handlestickycreate = () => {
    navigate(`/create`);
  };

  const truncateContent = (text, limit) => {
    if (text.length > limit) {
      return text.substring(0, limit) + "...";
    }
    return text;
  };

  return (
    <div className="blog-card">
      <h2 className="blog-title">{title}</h2>
      <p className="blog-author">By: {authorName}</p>
      <p className="blog-date">
        Published on: {new Date(createdAt).toDateString()}
      </p>
      <p className="blog-content">
        {truncateContent(content, 200)} {/* Truncate to 200 characters */}
      </p>
      <section className="bycategory-buttons-section">
        <div className="action-btn-box-bycategory-post">
          <img
            src={likeIcon}
            alt="Like"
            className="action-btn-box-bycategory-post-like"
          />
        </div>
        <button
          onClick={handleCardClick}
          className="Readmore-blog-bycategory-page"
        >
          Read More in details
        </button>
      </section>
      <section className="ation-btn-bycategory-page">
        <button
          className="btn-action-to-post-sticky"
          onClick={handlestickycreate}
        >
          Post a blog{" "}
          <img
            src={createimg}
            alt="Like"
            className="action-btn-box-bycategory-post-create"
          />
        </button>
      </section>
    </div>
  );
};

export default BlogCard;
