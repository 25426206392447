import React from "react";
import "./ProfilePicProgress.css"; // Create and import the CSS file for styling

const ProfilePicProgress = ({ progress, onConfirm }) => {
  return (
    <section className="upload-progress-container">
      <div className="profile-pic-progress">
        <h4>Uploading Your picture</h4>
        <div className="progress-bar" style={{ width: `${progress}%` }}></div>
        {progress === 100 && (
          <button className="confirm-button" onClick={onConfirm}>
            OK
          </button>
        )}
      </div>
    </section>
  );
};

export default ProfilePicProgress;
