import CreateNewBlog from "./Mobile/Components/Blogs/CreateNewBlog";
import React, { useEffect, useState } from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import BlogDetails from "./Mobile/Components/Blogs/BlogDetails";
import MarkdownGuide from "./Mobile/Guide/MarkdownGuide";
import Home from "./Mobile/Pages/Home";
import Signup from "./Mobile/Pages/Signup";
import Login from "./Mobile/Pages/Login";
import DashBoard from "./Mobile/Components/AccountDetails/DashBoard";
import Categories from "./Mobile/Pages/categories";
import Create from "./Mobile/Pages/Create";
import Account from "./Mobile/Pages/Account";
import Comments from "./Mobile/Pages/Commentspage";
import BlogTemplate from "./Mobile/Components/Blogs/BlogTemplate";
import Explore from "./Mobile/Pages/Explore";
import Postbycategory from "./Mobile/Components/Postbycategory/Postbycategory";
import Friendspage from "./Mobile/Pages/Friends";
import Splashpage from "./Mobile/Pages/Splashpage";
import UserHead from "./Mobile/Components/PostComponents/UserHead";
import FeadBackPage from "./Mobile/Pages/FeadBackPage";
import "./Mobile/assets/Fonts/fonts.css";
//
//

import "./App.css";
import { UserProvider } from "./Mobile/Contexts/UserContext";

const App = () => {
  ////
  //
  ////
  //
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 700) {
        window.location.href = "https://dblob.digihut.store/"; // Replace with your desktop version URL
      }
    };

    // Check screen width on component mount
    handleResize();

    // Add resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <UserProvider>
      <Router>
        <div className="router-container">
          {/* {isDesktop ? (
            <ComingSoon />
          ) : ( */}
          <Routes>
            <Route path="/" element={<Splashpage />} />
            <Route path="/home" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/Explore" element={<Explore />} />
            <Route path="/newblog" element={<CreateNewBlog />} />
            <Route path="/blog/:id" element={<BlogDetails />} />
            <Route path="/createnewblog" element={<CreateNewBlog />} />
            <Route path="/UserHead" element={<UserHead />} />
            <Route path="/UserHead" element={<UserHead />} />

            <Route path="/categories" element={<Categories />} />

            <Route path="/dashboard" element={<DashBoard />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/account" element={<Account />} />
            <Route path="/create" element={<Create />} />
            <Route path="/comments" element={<Comments />} />
            <Route path="/Friendspage" element={<Friendspage />} />
            <Route path="/MarkdownGuide" element={<MarkdownGuide />} />
            <Route path="/BlogTemplate" element={<BlogTemplate />} />
            <Route path="/FeadBackPage" element={<FeadBackPage />} />

            <Route path="/posts/:categoryName" element={<Postbycategory />} />
          </Routes>
          {/* )} */}
        </div>
      </Router>
    </UserProvider>
  );
};

export default App;
