import React, { useState, useEffect } from "react";
import axios from "axios";
import "./UserHead.css";
import dotsIcon from "./dots.png";
import Dotclick from "../Modal/Threedotsmodel";
import { useUser } from "../../Contexts/UserContext";

const UserHead = ({ name, createdOn, blogLink, userId }) => {
  const [showDotclick, setShowDotclick] = useState(false);
  const [profilePictureUrl, setProfilePictureUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isFollowing, setIsFollowing] = useState(false);
  const { user } = useUser();
  const FollowerId = user.userId;

  useEffect(() => {
    const fetchFollowStatus = async () => {
      try {
        const response = await axios.get(
          `https://coblog-backend.vercel.app/users/followedUsers/${FollowerId}`
        );
        const followedUsers = response.data;
        const isFollowing = followedUsers.some(
          (followedUser) => followedUser.user_id === userId
        );
        setIsFollowing(isFollowing);
      } catch (error) {
        console.error("Error checking follow status:", error);
      }
    };

    fetchFollowStatus();
  }, []);

  const handleFollow = async () => {
    try {
      await axios.post(
        "https://coblog-backend.vercel.app/following/followUser",
        {
          follower_id: FollowerId,
          followed_id: userId,
        },
        { headers: { "Content-Type": "application/json" } }
      );
      setIsFollowing(true);
    } catch (error) {
      console.error("Error following user:", error);
    }
  };

  const handleUnfollow = async () => {
    try {
      await axios.post(
        "https://coblog-backend.vercel.app/following/unfollowUser",
        {
          follower_id: FollowerId,
          followed_id: userId,
        },
        { headers: { "Content-Type": "application/json" } }
      );
      setIsFollowing(false);
    } catch (error) {
      console.error("Error unfollowing user:", error);
    }
  };

  const openDotclick = () => {
    setShowDotclick(true);
  };

  const closeDotclick = () => {
    setShowDotclick(false);
  };

  const Verified =
    "https://static.vecteezy.com/system/resources/thumbnails/022/597/484/small/3d-verification-social-media-png.png";

  const fallbackProfilePic =
    "https://cdn-icons-png.flaticon.com/128/3177/3177440.png"; // Update with the path to your fallback image

  let date;

  if (createdOn === undefined || createdOn === null) {
    date = new Date();
  } else {
    date = new Date(createdOn);
  }

  if (isNaN(date)) {
    date = new Date();
  }

  useEffect(() => {
    const fetchProfilePicture = async () => {
      if (userId) {
        try {
          const response = await axios.get(
            `https://coblog-backend.vercel.app/users/profilePicture`,
            {
              headers: {
                "Content-Type": "application/json",
              },
              params: {
                userId: userId,
              },
            }
          );

          if (response.data && response.data.profilePictureUrl) {
            setProfilePictureUrl(response.data.profilePictureUrl);
          } else {
            setProfilePictureUrl(""); // Handle no profile picture
          }
        } catch (error) {
          setProfilePictureUrl(""); // Handle errors
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    fetchProfilePicture();
  }, [userId]);

  return (
    <div className="user-head">
      <div className="left">
        <div className="right-box">
          {isLoading ? (
            <p>Pic</p>
          ) : (
            <img
              src={profilePictureUrl || fallbackProfilePic} // Use fallback image if profilePictureUrl is not available
              alt="Profile"
              className="user-head-photo"
              onError={(e) => (e.target.src = fallbackProfilePic)} // Set fallback image on error
            />
          )}
        </div>
        <div className="mid-name-box">
          <section
            className="verified-name-template"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "3px",
            }}
          >
            <span className="user-name">{name} </span>
            <img
              src={Verified}
              className="verified-icon-user-head"
              alt="VerifiedTick"
            />
          </section>
          <span className="date-created">📅 {date.toLocaleDateString()}</span>
        </div>
      </div>

      <div className="right">
        {isFollowing ? (
          <button onClick={handleUnfollow} className="Follow-btn-Blogtemplate">
            Unfollow
          </button>
        ) : (
          <button onClick={handleFollow} className="Follow-btn-Blogtemplate">
            Follow
          </button>
        )}
        <div className="end-box">
          <img
            src={dotsIcon}
            alt="Menu"
            className="dots-icon"
            onClick={openDotclick}
          />
          <Dotclick
            show={showDotclick}
            onClose={closeDotclick}
            blogLink={blogLink}
          />
        </div>
      </div>
    </div>
  );
};

export default UserHead;
