import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import homeIcon from "./home.png";
import categoriesIcon from "./Friends.png";
import searchIcon from "./Search.png";
import axios from "axios";
import "./BottomTabs.css";
import { useUser } from "../../Contexts/UserContext";

const BottomTabs = () => {
  const { user } = useUser();
  const [profilePictureUrl, setProfilePictureUrl] = useState("");
  const fallbackImageUrl =
    "https://cdn-icons-png.flaticon.com/128/3177/3177440.png";

  useEffect(() => {
    const fetchProfilePicture = async () => {
      if (user && user.userId) {
        try {
          const response = await axios.get(
            `https://coblog-backend.vercel.app/users/profilePicture`,
            {
              headers: {
                "Content-Type": "application/json",
              },
              params: {
                userId: user.userId,
              },
            }
          );

          if (response.data && response.data.profilePictureUrl) {
            setProfilePictureUrl(response.data.profilePictureUrl);
          } else {
            setProfilePictureUrl(fallbackImageUrl);
          }
        } catch (error) {
          console.error("Failed to fetch profile picture:", error);
          setProfilePictureUrl(fallbackImageUrl);
        }
      }
    };

    fetchProfilePicture();
  }, []);

  return (
    <nav className="bottom-nav">
      <NavLink to="/" className="nav-link" activeClassName="active">
        <img src={homeIcon} alt="Home" />
        <span>Home</span>
      </NavLink>

      <NavLink to="/Explore" className="nav-link" activeClassName="active">
        <img src={searchIcon} alt="Explore" />
        <span>Explore</span>
      </NavLink>

      <NavLink to="/Friendspage" className="nav-link" activeClassName="active">
        <img src={categoriesIcon} alt="Friends" />
        <span>Friends</span>
      </NavLink>

      <NavLink to="/Account" className="nav-link" activeClassName="active">
        <img
          src={profilePictureUrl}
          alt="Account"
          className="profile-photo"
          onError={(e) => {
            e.target.src = fallbackImageUrl;
          }}
        />
        <span>Account</span>
      </NavLink>
    </nav>
  );
};

export default BottomTabs;
