import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Profilebody.css"; // Import your CSS for styling
import { useNavigate } from "react-router-dom";
import CoverSection from "./CoverSection";
import camera from "../../assets/icons/Camera.png";
import LoginPrompt from "../Errors/LoginPrompt";
import { useUser } from "../../Contexts/UserContext";

import ProfilePicProgress from "../Errors/ProfilePicProgress";

const Profilebody = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [profilePictureUrl, setProfilePictureUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  //
  //
  ////

  const Verified =
    "https://static.vecteezy.com/system/resources/thumbnails/022/597/484/small/3d-verification-social-media-png.png";

  useEffect(() => {
    const fetchProfilePicture = async () => {
      if (user && user.userId) {
        try {
          const response = await axios.get(
            `https://coblog-backend.vercel.app/users/profilePicture`,
            {
              headers: {
                "Content-Type": "application/json",
              },
              params: {
                userId: user.userId, // Send userId as query parameter
              },
            }
          );

          if (response.data && response.data.profilePictureUrl) {
            setProfilePictureUrl(response.data.profilePictureUrl);
          } else {
            setProfilePictureUrl(""); // Handle no profile picture
          }
        } catch (error) {
          console.error("Failed to fetch profile picture:", error);
          setProfilePictureUrl(""); // Handle errors
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    fetchProfilePicture();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("user");
    navigate("/home");
  };

  const goToFeadBack = () => {
    navigate("/FeadBackPage");
  };

  const handleProfilePictureChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("userId", user.userId);
    formData.append("profilePicture", file);

    try {
      setShowProgress(true);
      const response = await axios.post(
        "https://coblog-backend.vercel.app/users/uploadProfilePic",
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          },
        }
      );

      if (response.data.profile_picture && response.data.profile_picture.data) {
        const base64String = `data:image/jpeg;base64,${response.data.profile_picture.data}`;
        setProfilePictureUrl(base64String);
      }
    } catch (error) {
      console.error("Error uploading profile picture:", error);
    }
  };

  const handleConfirm = async () => {
    try {
      setShowProgress(false);
      setUploadProgress(0);
      setIsLoading(true);

      // Fetch the updated profile picture after confirmation
      const response = await axios.get(
        `https://coblog-backend.vercel.app/users/profilePicture`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            userId: user.userId, // Send userId as query parameter
          },
        }
      );

      if (response.data && response.data.profilePictureUrl) {
        setProfilePictureUrl(response.data.profilePictureUrl);
      } else {
        setProfilePictureUrl(""); // Handle no profile picture
      }
    } catch (error) {
      console.error("Failed to fetch profile picture:", error);
      setProfilePictureUrl(""); // Handle errors
    } finally {
      setIsLoading(false);
    }
  };

  if (!user || user.userId.length === 0 || user.name === "") {
    return <LoginPrompt />;
  }

  return (
    <div className="profile-body">
      <CoverSection></CoverSection>
      <div className="profile-picture-section">
        <div className="profile-info-account">
          <div className="upload-pic-container">
            <div className="profile-photo-account-screen">
              {isLoading ? (
                <div></div> // You can use a loading spinner here
              ) : (
                <img
                  src={
                    profilePictureUrl ||
                    "https://cdn-icons-png.flaticon.com/128/3177/3177440.png"
                  } // Use placeholder if no URL
                  alt="Profile"
                  className="Profile-picture-account"
                />
              )}
              <label htmlFor="upload-input" className="camera-icon-label">
                <img
                  src={camera}
                  alt="Upload"
                  className="Camera-btn-pic-upload"
                />
              </label>
              <input
                type="file"
                id="upload-input"
                style={{ display: "none" }}
                onChange={handleProfilePictureChange}
              />
            </div>
          </div>

          <div className="user-name-account">
            <section className="name-box-account-verified">
              <h3>
                {user.name}{" "}
                <img src={Verified} className="verified" alt="VerifiedTick" />
              </h3>
            </section>
            <h6 className="user-email">✉ {user.email}</h6>
          </div>
        </div>
      </div>

      <div className="profile-Buttons-section">
        <div className="buttons">
          <button className="button" onClick={goToFeadBack}>
            FeadBack
          </button>
          <button className="button-logout" onClick={handleLogout}>
            Logout
          </button>
        </div>
      </div>
      <div className="user-details"></div>
      {showProgress && (
        <ProfilePicProgress
          progress={uploadProgress}
          onConfirm={handleConfirm}
        />
      )}
    </div>
  );
};

export default Profilebody;
