// src/components/Splashpage.jsx
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../Stylesheets/Splashpage.css";
import axios from "axios";
import spalshlogo from "../Components/Header/Logo.png";

const Splashpage = () => {
  const [isPosting, setIsPosting] = useState(true);
  const navigate = useNavigate();

  const saveIpAddress = async () => {
    const hasVisited = localStorage.getItem("hasVisited");
    if (!hasVisited) {
      try {
        const ipResponse = await axios.get("https://api.ipify.org?format=json");
        const ip_address = ipResponse.data.ip;

        const response = await axios.post(
          "https://coblog-backend.vercel.app/log-access",
          {
            ip_address,
          }
        );

        localStorage.setItem("hasVisited", "true");
        navigate("/home");
      } catch (error) {
        console.error("Error logging IP address:", error);
        navigate("/home");
        setIsPosting(false);
      }
    } else {
      navigate("/home");
    }
  };

  useEffect(() => {
    saveIpAddress();
  }, [navigate]);

  return (
    <div className="splash-container">
      <div className="logo-splash">
        <img src={spalshlogo} alt="Blogbook" className="splash-logo-img"></img>
      </div>
      {isPosting ? (
        <div className="loading-dots">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      ) : (
        <div className="error-message">
          <p>Failed to log IP address. Please try again later.</p>
        </div>
      )}
    </div>
  );
};

export default Splashpage;
